import React from 'react';
import Disposable from 'react-disposable-decorator';
import { successToast } from 'toast-service!sofe';
import { UserTenantProps } from 'cp-client-auth!sofe';
import { CpArea, CpDropdown, CpModal, CpButton } from 'canopy-styleguide!sofe';
import { noop } from 'lodash';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import StatusDropdown from 'src/common/status-dropdown.component.js';
import ClientRequestStatusDropdown from 'src/common/client-request-status-dropdown.component';
import { getTask, patchTask, getSubtasksForTasks } from 'src/resources/tasks.resource.js';
import { getTaskStr } from 'src/common/get-task-str';
import { getStatusDotStyles } from 'src/common/status-input.helper';
import { handleError } from 'src/common/error.helper';

import styles from './status-cell.styles.css';

// This component, and what it uses, are being exported
// and are shared to other projects
@UserTenantProps({ permissions: { userCanEditStatus: 'tasks_status' } })
@Disposable
class StatusCell extends React.Component {
  constructor() {
    super();
    this.state = {
      showCompleteSubtasksModal: false,
      loading: false,
    };
  }

  static defaultProps = {
    disabled: false,
    allowEditing: true,
  };

  render() {
    const selectedStatus = this.props.statuses.find(status => status.id === this.props.statusId);

    const { userCanEditStatus } = this.props.permissions || {};
    const disabled = this.props.disabled || !userCanEditStatus;

    const isClientRequest =
      this.props.task.request_tools &&
      this.props.task.request_tools.length > 0 &&
      this.props.task.request_tools[0].tool_type === 'simple';
    const taskStr = getTaskStr('task', this.props.loggedInUser);

    if (selectedStatus && (this.props.task.type === 'Client Request' || isClientRequest)) {
      return (
        <div style={{ maxWidth: '100%' }}>
          <ClientRequestStatusDropdown
            selectedStatus={selectedStatus.id}
            onSelect={this.updateClientRequestStatus}
            width={this.props.width}
            disabled={disabled}
          />
        </div>
      );
    }
    return (
      <div style={{ maxWidth: '100%' }}>
        <CpDropdown
          allowContentClicks
          disabled={disabled}
          contentWidth={'auto'}
          renderTrigger={({ toggle }) => (
            <CpArea style={{ borderRadius: '8px' }} disabled={disabled}>
              <div className={styles.selectStatus} onClick={this.props.allowEditing ? toggle : noop}>
                <span
                  className={styles.statusDot}
                  style={getStatusDotStyles(this.props.statusColor, this.props.statusName === 'No status', disabled)}
                ></span>
                <div className={`cp-ellipsis ${disabled ? 'cp-color-app-disabled-text' : ''}`}>
                  {this.props.statusName}
                </div>
              </div>
            </CpArea>
          )}
          renderContent={({ close, isOpen }) => (
            <StatusDropdown
              userId={this.props.loggedInUser.id}
              statuses={this.props.statuses}
              close={close}
              updateTaskStatus={this.updateStatus}
              updateStatuses={this.props.updateStatuses}
              selectedStatus={selectedStatus}
              isOpen={isOpen}
              positionAbsolute={false}
            />
          )}
        />
        <CpModal
          show={this.state.showCompleteSubtasksModal}
          onClose={() => this.setState({ showCompleteSubtasksModal: false })}
        >
          <CpModal.Header title="Would you like to update subtasks?" />
          <CpModal.Body>
            {`This ${taskStr} contains subtasks that are not complete. Would you like to update all subtasks as well?`}
          </CpModal.Body>
          <CpModal.Footer>
            <CpButton className="cp-mr-8" onClick={this.markAllSubtasksCompleted} showLoader={this.state.loading}>
              Yes
            </CpButton>
            <CpButton btnType="flat" onClick={this.closeCompleteSubtasksModal} showLoader={this.state.loading}>
              No
            </CpButton>
          </CpModal.Footer>
        </CpModal>
      </div>
    );
  }

  closeCompleteSubtasksModal = () => {
    const completedStatus = this.props.statuses.find(s => s.id === 'COMPLETED');
    this.props.updateTaskAttributes({
      taskIds: [this.props.task.id],
      newValues: {
        status_color: completedStatus.color,
        status_name: completedStatus.name,
        status_id: 'COMPLETED',
      },
    });
    this.setState({
      showCompleteSubtasksModal: false,
    });
  };
  markAllSubtasksCompleted = () => {
    const { task, statuses } = this.props;
    this.setState({ loading: true });
    const markAllCompleted = (parentTask = task) => {
      const getSubtasks = parentTask.subtasks
        ? of({ [parentTask.id]: parentTask.subtasks })
        : getSubtasksForTasks([parentTask.id], this.props.visibleColumns || []); // visible columns is undefined sometimes?
      const completedStatus = statuses.find(s => s.id === 'COMPLETED');

      getSubtasks
        .pipe(
          mergeMap(subtasks =>
            patchTask({
              ids: parentTask.id,
              task: {
                status_id: 'COMPLETED',
                subtasks: subtasks[parentTask.id].map(subtask => ({
                  ...subtask,
                  status_id: 'COMPLETED',
                })),
              },
            })
          )
        )
        .subscribe(
          () => {
            this.props.updateTaskAttributes({
              taskIds: [parentTask.id, ...(parentTask.subtasks ? parentTask.subtasks.map(s => s.id) : [])],
              newValues: {
                subtask_completed_sum: parentTask.subtask_total,
                status_color: completedStatus.color,
                status_name: completedStatus.name,
                status_id: 'COMPLETED',
              },
              shouldPatch: false,
            });
            this.setState({ showCompleteSubtasksModal: false, loading: false });
            successToast('All subtasks marked as completed.');
          },
          err => {
            this.setState({ loading: false });
            handleError(err);
          }
        );
    };
    if (task.parent_task_id) {
      getTask(task.parent_task_id).subscribe(parentTask => {
        markAllCompleted(parentTask);
      }, handleError);
    } else {
      markAllCompleted();
    }
  };
  updateStatus = statusId => {
    // Update in memory
    const newStatus = this.props.statuses.find(status => status.id === statusId);

    if (
      statusId === 'COMPLETED' &&
      this.props.task.subtask_total > 0 &&
      this.props.task.subtask_completed_sum < this.props.task.subtask_total
    ) {
      this.setState({
        showCompleteSubtasksModal: true,
      });
    } else {
      this.props.updateTaskAttributes({
        taskIds: [this.props.task.id],
        newValues: {
          status_color: newStatus.color,
          status_name: newStatus.name,
          status_id: statusId,
        },
      });
    }
  };

  updateClientRequestStatus = newStatus => {
    this.props.updateTaskAttributes({
      taskIds: [this.props.task.id],
      newValues: {
        status_color: newStatus.color,
        status_name: newStatus.label,
        status_id: newStatus.value,
      },
      isClientRequest: true,
    });
  };
}

export default React.memo(StatusCell);
